.app__laurels-awards{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 3rem;
}
.app__laurels-awards_card{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;
    margin: 1rem;
}
.app__laurels-awards_card img{
    width: 50px;
    height: 50px;
}
.app__laurels-awards_card-content{
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}
@media screen and (min-width:1900px) {
    .app__laurels-awards_card{
        min-width: 390px;
    }
}
@media screen and (max-width:450px) {
    .app__laurels-awards_card{
        min-width: 100%;
        margin: 1rem 0;
    }
}