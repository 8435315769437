.app__footeroverlay{
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: column;
    position: absolute;
}
.app__footeroverlay-black{
    height: 25%;
    background: var(--color-black);
}
.app__footeroverlay-img{
    height: 75%;

}
