.app__specialmenu{
    flex-direction: column;
    background: var(--color-black);
}
.app__specialmenu-title{
    margin-bottom: 2rem;
    text-align: center;
    color: aliceblue;
}
.app__specialmenu-menu{
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}
.app__specialmenu-menu_heading{
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04rem;
    color: var(--color-white);
}
.app__specialmenu-menu_wine,
.app__specialmenu-menu_cocktails {
    flex: 1;
    width: 100%;
    flex-direction: column;
}
.app__specialmenu-menu-img img{
    width: 100%;
    height: auto;

}
.app__specialmenu-menu_items{
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    width: 100%;
}
@media screen and (max-width:2000px) {
    /* .app__specialmenu-menu{
        flex-direction: column;
        align-items: center;
        width: 100%;
    } */
    .app__specialmenu-menu-img{
        width: 650px;
    }
    .app__specialmenu-menu-img img{
        height: 920px;
    }
}
@media screen and (max-width:1150px) {
    .app__specialmenu-menu{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .app__specialmenu-menu-img{
        margin: 3rem 0;
    }
}
@media screen and (max-width:650px) {
    .app__specialmenu-menu-img{
        width: 100%;
    }
    .app__specialmenu-menu_heading{
        font-size: 35px;
        line-height: 48.5px;
    }
}